// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "vdx/components/MKTypography";

import FilledInfoCard from "vdx/components/FilledInfoCard";

function ObjectivePage({ studyLink }) {
  return (
    <MKBox component="section" py={{ xs: 1, md: 6 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h1" my={1} color="vdx_grey">
              Quel est l’objectif de
              l’étude VisioCyt 
              Screening ?
            </MKTypography>
            <MKTypography variant="body1" mb={2} color="vdx_grey">
              L’objectif est de développer un algorithme 
              d’intelligence artificielle capable de détecter des 
              marqueurs prédictifs du cancer de la vessie dans les 
              échantillons d’urine, afin d’obtenir un test de 
              dépistage efficace pour ce cancer chez les patients à 
              haut risque. 
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <FilledInfoCard
              title="Ce projet n'est possible qu'avec votre participation !"
              description="Votre expertise en tant que médecin est cruciale pour identifier les patients potentiellement à risque."
              action={{
                type: "external",
                route: studyLink,
                color: "info",
                label: "Participer à l’étude",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ObjectivePage;