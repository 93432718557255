/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "vdx/components/MKTypography";
import MKButton from "vdx/components/MKButton";

function FilledInfoCard({
  variant = "contained",
  color = "info",
  title,
  description,
  action = false,
}) {
  return (
    <MKBox
      display={{ xs: "block", md: "flex" }}
      variant={variant}
      bgColor={variant === "contained" ? "grey-100" : color}
      borderRadius="xl"
      pt={{ xs: 2, sm: 6 }}
      pb={{ xs: 2, sm: 6 }}
      px={6}
      sx={{ boxShadow: 3 }}
    >
      <MKBox lineHeight={1}>
        <Stack spacing={{ sm: 4 }} >
          <MKTypography
            display="block"
            variant="h4"
            color={variant === "contained" || color === "light" ? "black" : "white"}
            fontWeight="bold"
            mb={1}
            sx={{textAlign: "center"}}
          >
            {title}
          </MKTypography>
          <MKTypography
            display="block"
            variant="body1"
            color={variant === "contained" || color === "light" ? "vdx_grey" : "white"}
            mb={2}
            sx={{textAlign: "center"}}
          >
            {description}
          </MKTypography>
          {action &&
            <MKBox display="flex" justifyContent="center">
              <MKButton color="vdx_green" size="large" onClick={() => window.open(action.route, "_blank")}>
                Participer à l'étude
              </MKButton>
            </MKBox>
          }
        </Stack>
      </MKBox>
    </MKBox>
  );
}

// Typechecking props for the FilledInfoCard
FilledInfoCard.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default FilledInfoCard;
