/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "vdx/components/MKButton";

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';

import colors from "vdx/assets/theme/base/colors"

function Footer() {
  let vitadx_socials = [
    {
      icon: <LinkedInIcon />,
      link: "https://fr.linkedin.com/company/vitadx",
    },
    {
      icon: <XIcon/>,
      link: "https://x.com/vitadx_sa",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCURwVbaAIdjsgot6J5c22mA",
    },
  ]

  let popsicube_socials = [
    {
      icon: <LinkedInIcon />,
      link: "https://fr.linkedin.com/company/popsi-cube",
    },
  ]

  return (
    <MKBox component="footer"
        sx={{
          backgroundColor: colors.vdx_green.main,
        }}>
      <Container>
        <Grid container spacing={{ xs: 1, sm: 3 }} pt={{ xs: 1, sm: 4 }}>
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <MKTypography variant="h4" color="white" mb={1}>
              VitaDX
            </MKTypography>
            <MKTypography variant="body1" color="white" mb={2} pb={1} pr={3}>
              Développe et commercialise des solutions de diagnostic du cancer combinant l’intelligence artificielle et l’analyse d’images appliquées à la cytologie.
            </MKTypography>
            <MKTypography variant="body1" color="white" mb={2} pb={10} pr={3} component="a" href={"https://www.vitadx.com"} target="_blank">
              www.vitadx.com
            </MKTypography>
            <MKBox display="flex" alignItems="center">
              {vitadx_socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="body1"
                  color="white"
                  mr={key === vitadx_socials.length - 1 ? 0 : 3}
                  sx={{ fontSize: "2rem", pt: 3 }}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={4} >
            <MKTypography variant="h4" color="white" mb={1}>
              Popsicube
            </MKTypography>
            <MKTypography variant="body1" color="white" mb={{ xs: 2, lg: 6.5 }} pb={1} pr={3}>
              Organisation de recherche clinique (CRO) qui agit au nom du promoteur de l’étude VitaDX.
            </MKTypography>
            <MKTypography variant="body1" color="white" mb={2} pr={3} component="a" href={"https://www.popsicube.com"} target="_blank">
              www.popsicube.com
            </MKTypography>
            <MKBox display="flex" alignItems="center">
              {popsicube_socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="body1"
                  color="white"
                  mr={key === popsicube_socials.length - 1 ? 0 : 3}
                  sx={{ fontSize: "2rem", pt: 3 }}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={4}>
            <MKTypography variant="body1" color="white" mb={3} pb={1} pr={3}>
              Vous souhaitez en discuter avec vos confrères ?
            </MKTypography>
              <MKButton color="vdx_grey" size="large">
                <a href={process.env.PUBLIC_URL + '/Flyer_VisioCytScreening_Medecin_Présentation Etude Clinique_FR_VA.pdf'} 
                download="Flyer_VisioCytScreening_Medecin_Présentation Etude Clinique_FR.pdf" style={{ color: "white" }}>
                  Télécharger le flyer
                </a>
              </MKButton>
          </Grid>
          <Grid item xs={12} pr={3} sx={{ textAlign: "center", mb: 3 }}>
            <Divider />
            <MKTypography color="white">
              Copyright © 2024 VitaDX International
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Footer;
