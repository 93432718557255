// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "vdx/components/MKButton";
import MKTypography from "vdx/components/MKTypography";

// Coworking page component
import AboutUsOption from "vdx/components/AboutUsOption";

import gobeletIcon from "vdx/assets/images/gobelet.svg";
import discussionIcon from "vdx/assets/images/discussion.svg";
import fumeurIcon from "vdx/assets/images/fumeur.png";

function PatientsPage({ studyLink }) {
  return (
    <MKBox component="section" py={{ xs: 1, md: 6 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h1" color="vdx_grey" my={1}>
              Quels sont les patients
              éligibles et comment
              participeront-ils ?
            </MKTypography>
            <MKBox
              display={{ xs: "block", md: "flex" }}
              variant="contained"
              bgColor="white"
              borderRadius="xl"
              pt={3.5}
              pb={3}
              px={3}
              sx={{ mt: {xs: 3, sm: 5, lg: 10}, boxShadow: 3 }}
            >
              <AboutUsOption
                icon={fumeurIcon}
                iconSize="6rem"
                content={
                  <>
                    Hommes âgés de 65 à 84 ans,
                    fumeurs (20 « paquet-année »/
                    20 UPA) ou anciens fumeurs
                    (arrêt inférieur à 5 ans)
                  </>
                }
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={5} sx={{ ml: { lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack spacing={{ xs: 2, sm: 6 }}>
              <div>Vos patients devront :</div>
              <div>
                <AboutUsOption
                  icon={gobeletIcon}
                  iconSize="3rem"
                  content={
                    <>
                      Fournir trois échantillons d'urine sur une
                      période de deux ans dans votre cabinet.
                    </>
                  }
                />
                <AboutUsOption
                  icon={discussionIcon}
                  iconSize="3rem"
                  content={
                    <>
                        Répondre à un questionnaire avec nos
                        infirmières de recherche pour détecter
                        d’éventuels signes de cancer de la vessie.
                    </>
                  }
                />
              </div>
              <div>
                Vous avez cette patientèle ?
                <MKBox display="flex" alignItems="center" mt={2}>
                  <MKButton color="vdx_green" size="large" onClick={() => window.open(studyLink, "_blank")}>
                    Participer à l’étude
                  </MKButton>
                </MKBox>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default PatientsPage;