/*
=========================================================
* Material Kit 2 PRO React - v2.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useRef } from "react";

// @mui material components
import Header from "vdx/sections/Header";
import ObjectivePage from "vdx/pages/ObjectivePage";
import PatientsPage from "vdx/pages/PatientsPage";
import WhyPage from "vdx/pages/WhyPage";
import HelpUsPage from "vdx/pages/HelpUsPage";
import ContactPage from "vdx/pages/ContactPage";
import Footer from "vdx/sections/Footer";

import { study_link, investigation_link } from 'vdx/utils/config'

function Main() {
  const contactRef = useRef(null)

  return (
    <>
      <Header contactRef={contactRef} studyLink={study_link} investigationLink={investigation_link}/>
      <ObjectivePage studyLink={study_link}/>
      <PatientsPage studyLink={study_link}/>
      <WhyPage studyLink={study_link}/>
      <HelpUsPage investigationLink={investigation_link}/>
      <ContactPage myRef={contactRef}/>
      <Footer />
    </>
  );
}

export default Main;
